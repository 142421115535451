import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { CreatedRes, ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type UserCreateReq = {
  company: number;
  email: string;
  name: string;
  password: string;
  phone_number: string;
  company_role: number;
  group_ids: number[];
};

export type UserCreateRes = CreatedRes;

const useUserCreateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<UserCreateRes, AxiosError<ErrorRes>, UserCreateReq>(
    async (req: UserCreateReq): Promise<UserCreateRes> => {
      return (
        await axiosInstance()
          .post<UserCreateRes>('/user/create', req)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useUserCreateMutation;
